import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function SilverScreen() {
  return (
    <Layout>
      <Helmet title="Silver Screen | Michael Weslander & John Schrad" />

      <h1>Silver Screen</h1>
      <h2>Michael Weslander & John Schrad</h2>
      <h3>Key: C</h3>
      <h4>Tempo: 90</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>I will meet you in the autumn</p>
          <p>In a trancendental state</p>
          <p>A transition to new horizons</p>
          <p>I will kiss you on the mouth</p>
          <p>In the shadow of a house</p>
          <p>No hope of a future</p>
          <p>In our hearts</p>
        </Verse>

        <Verse>
          <p>And in winter time</p>
          <p>I will love you in my mind</p>
          <p>As I sit here</p>
          <p>A thousand miles away</p>
          <p>You will ask me to stay</p>
          <p>But darling it can't be that way</p>
          <p>I'm moving west</p>
          <p>To a brave new world</p>
        </Verse>

        <Chorus>
          <p>It will be</p>
          <p>Our wonton dreams</p>
          <p>Of a romance on the silver screen</p>
          <p>And morning doubts</p>
          <p>Over last night's romp abouts</p>
          <p>Oh you know me</p>
          <p>Honey if I don't leave</p>
          <p>I'll still be gone</p>
        </Chorus>

        <Verse>
          <p>Spring will come at last</p>
          <p>Through the window of my home</p>
          <p>On a beach somehwere that</p>
          <p>I have never known</p>
          <p>And I'll think of your smile</p>
          <p>And how far away you are</p>
          <p>And a simpler time</p>
          <p>Not so long ago</p>
        </Verse>

        <Chorus>
          <p>It will be</p>
          <p>Our wonton dreams</p>
          <p>Of a romance on the silver screen</p>
          <p>And morning doubts</p>
          <p>Over last night's romp abouts</p>
          <p>Oh you know me</p>
          <p>Honey if I don't leave</p>
          <p>I'll still be gone</p>
        </Chorus>

        <Bridge>
          <p>Instrumental</p>
        </Bridge>

        <Verse>
          <p>Summer heat will come</p>
          <p>To the shores where I am from</p>
          <p>Where I've been for far too long now</p>
          <p>Here by myself</p>
          <p>All alone</p>
          <p>And I'll miss you tonight</p>
          <p>My tears blinded by the city lights</p>
          <p>And the morning glowing bright</p>
          <p>Without you</p>
        </Verse>

        <Chorus>
          <p>Oh it will be</p>
          <p>Our wonton dreams</p>
          <p>Of a romance on the silver screen</p>
          <p>And morning doubts</p>
          <p>Over last night's romp abouts</p>
          <p>Oh you know me</p>
          <p>Honey if I don't leave</p>
          <p>I'll still be gone</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
